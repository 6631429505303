import Vue from 'vue'
import Router from 'vue-router'
// import Social from '@/components/Social'
import Home from '@/Views/index'

Vue.use(Router)

export default new Router({
    routes: [
        // { path: '/', component: Social },
        { path:'/', component: Home} ,
        { path:'*', component: Home }
    ]
});