import Vue from 'vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import * as VueGoogleMaps from 'vue2-google-maps'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'wowjs/css/libs/animate.css'


import 'swiper/swiper-bundle.css'

import App from './App.vue'
import router from './router'
import wow from 'wowjs'

import './style/style.scss'


Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDhLpeNESyubc96F2qzUX6_5uqwCZSK7sQ',
    libraries: 'places',
  }
});
Vue.prototype.$wow = wow

new Vue({
  el: '#app',
  router,
  render: h => h(App),
})
