<template>
  <div class="header" :class="[scroll?'scroll':'', isOpen?'open':'']">
    <div class="logo">
      <b-img center :src="scroll?images[1]:images[0]" fluid alt="Responsive image"></b-img>
    </div>
    <div class="reserve" ><a :href="booking_url" target="reserve" class="btn-booking">RESERVE A TABLE</a></div>
    <b-navbar fixed="top" toggleable="md" type="dark" class="px-4">
      <b-navbar-brand href="#"></b-navbar-brand>

      <b-navbar-toggle @click="openMenu" target="nav-collapse">
        <template #default="{ expanded }">
          <b-icon v-if="expanded" icon="x" style="width:36px;height:36px;"></b-icon>
          <b-icon v-else icon="list" style="width:36px;height:36px;"></b-icon>
        </template>
      </b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="mx-auto" style="color:#8E6919">
          <b-nav-item @click="toSection('#address')">OVERVIEW</b-nav-item>
          <b-nav-item @click="toSection('#chef')">CHEF</b-nav-item>
          <b-nav-item @click="toSection('#food')">FOOD & DRINK</b-nav-item>
          <b-nav-item @click="toSection('#story')">STORY</b-nav-item>
          <div class="nav-contact">
            <a :href="fb_url" target="_blank">
                <b-icon icon="facebook"></b-icon>
            </a>
            <a :href="ig_url" target="_blank">
                <b-icon icon="Instagram" ></b-icon>
            </a>
            <a :href="`tel:${phone}`">
                <b-icon icon="telephone"></b-icon>
            </a>
            <a :href="wts_url" target="_blank">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="1em" height="1em" class="bi bi-whatsapp b-icon" viewBox="0 0 16 16">
                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                </svg>
            </a>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <div class="desktop-nav">
      <b-row>
            <b-col>
                <div class="sitemap">
                    <div><a href="javscript:void(0)" @click="toSection('#overview')">OVERVIEW</a></div>
                    <div><a href="javscript:void(0)" @click="toSection('#chef')">CHEF</a></div>
                    <div><a href="javscript:void(0)" @click="toSection('#food')">FOOD & DRINK</a></div>
                    <div><a href="javscript:void(0)" @click="toSection('#story')">STORY</a></div>
                </div>
            </b-col>
            <b-col>
             
            </b-col>
            <b-col class="contact">
                <div><a :href="fb_url" target="_blank">
                    <b-icon icon="facebook"></b-icon>
                </a></div>
                <div><a :href="ig_url" target="_blank">
                    <b-icon icon="Instagram" ></b-icon>
                </a></div>
                <div><a :href="`tel:${phone}`">
                    <b-icon icon="telephone"></b-icon>
                </a></div>

                <div><a :href="wts_url" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" width="1em" height="1em" class="bi bi-whatsapp b-icon" viewBox="0 0 16 16">
                        <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                    </svg>
                </a></div>
            </b-col>     
        </b-row>
      </div>
  </div>
</template>

<script>
import logo from '@/assets/logo_black.png'
import image from '@/assets/logo.dfa426cd.png'
import store from '@/store'

export default {
    name:'topnav',
    data() {
      return {
        booking_url: store.url.reserve,
        fb_url:store.url.fb,
        ig_url:store.url.ig,
        wts_url:store.url.wts,
        phone:store.phone,
        images:[image,logo],
        scroll: false,
        isOpen: false
      }
    },
    mounted() {
      document.addEventListener("scroll", () => {
        const pos = window.scrollY;
        if (pos >= 700) {
          this.scroll = true;
        }else {
          this.scroll = false;
        }
      })
    },
    methods:{
      toSection:function(ele){
        const iTop  = document.querySelector(ele).offsetTop - 50;

        try{
          if(this.isOpen) this.openMenu();
        window.scrollTo({
          top: iTop,
          left: 0,
          behavior: 'smooth'
        });
        }catch(e){ console.log(e) }
      },
      toTop:function(){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      },
      openMenu() {
        this.isOpen = !this.isOpen;
      }
    }
    
}
</script>
<style lang="scss" scoped>
.header {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99999;

  .logo {
    width: clamp( 100px, 20vw, 200px);
    height: auto;
    position: fixed;
    top: 0px;
    left: 0px;
    margin: 0 30px;
  }
  .reserve {
    margin: 50px 30px;
    padding: 0px 10px;
    position: fixed;
    top: 0px;
    right: 0px;
    font-size: 34px;
    color: #ffffff;
    border-top: 4px solid rgb(255,182,0);
    border-bottom: 4px solid rgb(201,59,0);
    
    cursor: pointer;
    outline: none;

    a {
      color: #ffffff;
      text-decoration: none;
      padding:0 5px;
    }
    @media screen and (max-width: 767px) {
      font-size: 16px;
      right: 60px;
      margin-top: 15px;
      z-index: 9999;
    }
  }
  .navbar {
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }

    button {
      border: none;
      color: #ffffff;
    }
  }
  .navbar-collapse {
    font-size: 40px;
    padding-top: 60px;
    &.show{
      .nav-link {
        color: #000000;
      }
    }

    .nav-contact {
      div {
        margin: 0 10px;
      }
    }
  }
  .desktop-nav {
    padding: 20px;
    width: 100vw;
    z-index: 999999;
    position: fixed;
    bottom: 0px;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .arrow {
      width: 14px;
      height: auto;
    }

    a{
          color:#ffffff;
          font-size: 14px;
          line-height: 1em;
          text-decoration: none;

          padding: 7px;
      }
    .sitemap {
          text-align: left;
          div {
              font-family: 'Oswald', sans-serif;
              font-weight: lighter;
              display: block;
              text-align: left;
              width: 100%;
              border-left: 1px solid #ffffff;
              padding-left: 15px;

              &:first-child {
                  padding-top: 0px;
              }
              &:last-child {
                  padding-bottom: 0px;
              }
          }
      }
      .contact {
          text-align: right;

          div {
              display: block;
              margin: 0px;

              &:first-child {
                  padding-top: 0px;
              }
              &:last-child {
                  padding-bottom: 0px;
              }
          }
      }
  }
  &.open,
  &.scroll {
    button {
      color: #000000
    }
    a {
        color: #000000;
    }
    .sitemap {
      div {
        color: #000000;
        border-left: 1px solid #000000;
      }
    }
    .arrow {
      display: none;
    }
  }
  &.open {
    position: fixed;
    background-color: rgba(255 ,255, 255, 0.95);
  }
  &.scroll {
    .reserve {
      a {
        // background-color: #f1f1f1;
        text-shadow: 2px 2px 0px rgba(255 ,255, 255, 0.4);
      }
    }
  }
}

</style>
