<template>
    <section id="chef">
        <div class="content">
        <div class="block"></div>
        <div class="chef-pic">
            <b-img center fluid :src="images[0]"  alt="Center Responsive image"></b-img>
        </div>
        <div class="description">
            <div class="title"><h1 class="white">JOEY<br/>CHENG</h1></div>
            
            <p>Helmed by Chef Joey, who is Canadian born Chinese, is one of the young Asian Canadian chefs returning to Hong Kong to reunite and explore his family’s ethnic roots and heritage.</p>

            <p>Upon his cultural exchanges and travels in exploring traditional Asian delicacies he was inspired to share his Western upbringing and passion for Western culinary arts. </p>
            
            <p>Making his passion for food more than a dream, Chef Joey honed and mastered his skills to the professional level from one of the best Culinary Arts School in the world, Kendall College in Chicago.</p>

        </div>
        <b-modal id="my-modal" :hide-header="true" :hide-footer="true">
            <iframe src="https://www.youtube.com/embed/s8DHOvrnYo0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </b-modal>
    </div>
    </section>
</template>
<script>

import im2 from '@/assets/chef.jpg'

export default {
    data(){
        return {
            images:[im2]
        }
    }
}
</script>
<style lang="scss" scoped>
section {
    padding-bottom: 100px;
    background: linear-gradient(to bottom, transparent 20%, #ffb701 20%, #ffb701 );
    
    @media only screen and (max-width: 768px)  {
        background: linear-gradient(to bottom, transparent 35%, rgb(201, 59, 0) 35%, rgb(201, 59, 0));
    }
}

.block {
    position: absolute;
    z-index: -1;
    top: 0px;
    left: 0px;
    width: 65%;
    height: 70%;
    background-color: #cd3b02;
    
    @media only screen and (max-width: 768px)  {
        display: none;
    }
}
.content {
    position: relative;
    min-height: 56vw;
    z-index: 1;
}


.title {
    width: 100%;

    h1{
        font-size: 120px;
        text-align: left;
        line-height: 1em;
        padding: 30px 0px;
        @media only screen and (max-width: 768px)  {
            padding: 0px;
           
        }
        @media only screen and (max-width: 414px)  {
            font-size: 80px;
        }
    }
}
.description {
    position: absolute;
    width: 60%;
    height: 100%;
    padding-left: 20px;
    left: 0px;

    background-image: url("~@/assets/bg_chef.svg");
    background-position: left bottom;
    background-repeat: no-repeat;

    @media only screen and (max-width: 768px)  {
        position: relative;
        padding-right: 20px;
        margin-top: -20%;
        width: 100%;
        // padding: 0px 7vw;
    }
    @media only screen and (max-width: 414px)  {
        margin-top: 0px;
    }
    p {
        color: #FFFFFF;
    }
}
.chef-pic {
    position: absolute;
    width: 40%;
    right: 0px;
    height: auto;
    padding-top: 10%;
    @media only screen and (max-width: 768px)  {
        position: relative;
        margin-left: auto;
        width: 60%;
    }
}

</style>
<style lang="scss">
.modal-content {
    background: transparent;
}
.modal-body {
    min-width: 370px;
    min-height: 400px;
    width: 100%;
    height: auto;
    padding: 0px;
    background: transparent;

    iframe {
        width: 560px;
        height: 360px;
    }
}
</style>