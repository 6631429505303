<template>
    <section id="overview">
        <div class="title">
            <div>
                <h1 class="red">ON THE SPOT</h1>
            </div>
        </div>
        <div>
            <div class="preview"><b-img :src="banner" class="image" /></div>
            <div class="discription">
                <p>Nestled in the heart of Tin Hau located on Electric Road, just a 2-minute walk from Tin Hau MTR Exit A , On the Spot is the perfect spot fused with cafe, bar and restaurant for urban dwellers to escape the city’s hustle and indulge in contemporary exquisite western cuisine. </p>
                
                <p>As the name suggests, Western dishes are curated on the spot offering diners an incredible and substantial food experience, be it for the aficionado or the novice looking for a memorable food adventure.</p>
            </div>
        </div>
    </section>
    
</template>
<script>
import img1 from '@/assets/overview/1.jpg'
import img2 from '@/assets/overview/2.jpg'
import img3 from '@/assets/overview/3.jpg'
import img4 from '@/assets/overview/4.jpg'
import img5 from '@/assets/overview/5.jpg'
import img6 from '@/assets/overview/6.jpg'
import img7 from '@/assets/overview/7.jpg'
import img9 from '@/assets/overview/9.jpg'
import img10 from '@/assets/overview/10.jpg'
import img14 from '@/assets/overview/14.jpg'
import img15 from '@/assets/overview/15.jpg'
import img16 from '@/assets/overview/16.jpg'
import img17 from '@/assets/overview/17.png'


export default {
    name: 'overview',
    data() {
        return {
            speed: 3000,
            index: 0,
            banner: img1,
            images: [
                img1,
                img2,
                img3,
                img4,
                img5,
                img6,
                img7,
                img9,
                img10,
                img14,
                img15,
                img16,
                img17
            ]
        }
    },
    mounted() {
        this.loadBanner();
    },
    methods: {
        loadBanner () {
            setInterval(()=> {
                this.index ++;
                if(this.index >= this.images.length) this.index = 0;
                this.banner = this.images[this.index];
            },this.speed)
        }
    }
}
</script>

<style lang="scss" scoped>
section {
    min-height: 100vw;
    /* background-image: url('') */
    background-repeat: no-repeat;
    background-position: left 5%;
    background-image: url("~@/assets/bg_overview.svg");

    @media only screen and (max-width: 768px) {
        margin-top: 80px;
        background: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,0) 70%, #ffb701 70%, #ffb701 100%);
    }

}
.title {
    text-align: left;
    font-size: 68px;
    margin-top: 30px;
    position: relative;
    z-index: 99;
}

.discription{
    position: absolute;
    width: 45%;
    height: auto;
    color: rgb(88,89,91);
    padding: 5vw 20px 20px 0px;
    margin-top: 5%;
    z-index: 1;
    @media only screen and (max-width: 768px)  {
        position: relative;
        width: 85%;
        margin-left: 15%;
    }
}
.preview {
    position: absolute;
    z-index: 0;
    top: 30%;
    right: 0px;
    width: 60%;
    height: auto;
    overflow: hidden;
    background: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,0) 70%, #ffb701 70%, #ffb701 100%);
    

    @media only screen and (max-width: 768px)  {
        background: none;
        position: relative;
        width: 100%;
        margin-top: 30px;
    }

    .image {
        margin: 30px 15%;
        width: 100%;
        height: auto;
    }
}
p {
    text-align: left;
}
</style>