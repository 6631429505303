<template>
    <section class="footer">
        <b-container>
        <b-row>
            <b-col>
            </b-col>
            <b-col>
                <div class="w-50 mx-auto mb-4">
                    <b-img center :src="images[0]" fluid alt="Responsive image"></b-img>
                </div>
            </b-col>
            <b-col class="contact">
            </b-col>     
        </b-row>
        </b-container>
    </section>
</template>
<script>
import image from '@/assets/logo_black.png'
import store from '@/store'

export default {
    data(){
        return{
            images:[image],
            phone:store.phone,
            fb_url:store.url.fb,
            ig_url:store.url.ig,
            wts_url:store.url.wts,
        }
    }
}
</script>
<style lang="scss" scoped>
.footer{
    /* background-color:#242021; */
    color:#fff;
    padding:3.125rem 0;

    @media screen and (max-width: 767px) {
        display: none;
    }

/* .white img{
    filter: grayscale(1) invert(100%)
} */
   .title{
        font-weight: 300;
        font-size: 1.25rem;
        font-family: "Didot LT W05 Italic",Didot,"Hoefler Text",Garamond,"Times New Roman",serif;
        font-style: italic;
    }
    .light{
        color:#ccc;
        font-size: 13px;
    }
    svg{
        color:#555555;
    }
    .copyright{
        color: #555555;
        font-size: 11px;
    }
    a{
        color:#555555;
        font-size: 14px;
        line-height: 1em;
        text-decoration: none;

        padding: 7px;
    }
    .copyright a:hover{
        text-decoration: underline;
    }

    .sitemap {
        text-align: left;
        a{
            font-family: 'Oswald', sans-serif;
            display: block;
            text-align: left;
            width: 100%;
            border-left: 1px solid #555555;
            padding-left: 15px;

            &:first-child {
                padding-top: 0px;
            }
            &:last-child {
                padding-bottom: 0px;
            }
        }
    }
    .contact {
        text-align: right;

        a {
            display: block;
            margin: 0px;

            &:first-child {
                padding-top: 0px;
            }
            &:last-child {
                padding-bottom: 0px;
            }
        }
    }
}
</style>