<template>
    <div class="home">
        <TopNav />
        <Banner />
        <Overview class="wow bounceInLeft" />
        <Chef class="wow bounceInRight" />
        <Speak class="wow fadeIn"/>
        <Food class="wow fadeIn"/>
        <Sustainability class="wow bounceInRight"/>
        <Story class="wow bounceInLeft"/>
        <Address class="wow bounceInRight" />
        <Footer />
    </div>
</template>
<script>
import TopNav from '@/components/TopNav.vue'
import Banner from '@/components/Banner.vue'
import Footer from '@/components/Footer.vue'

import Food from './Home/Food.vue'
import Address from './Home/Address.vue'
import Overview from './Home/Overview.vue'
import Chef from './Home/Chef.vue'
import Story from './Home/Story.vue'
import Sustainability from './Home/Sustainability.vue'
import Speak from './Home/Speak.vue'

export default {
    components:{
        TopNav,
        Banner,
        Footer,
        Food,
        Address,
        Overview,
        Chef,
        Story,
        Sustainability,
        Speak
    },
    mounted(){
        new this.$wow.WOW({
            live: true
        }).init()
    }
}
</script>
<style scoped>
.home{
    background-image: url('~@/assets/bg_texture.jpg');
    background-color: #f1f1f1;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    overflow: scroll;
    padding: 0px;
    min-width: 320px;
}


</style>
