<template>
<section id="food">
        <div class="menu content">
            <div class="title">
                <h1 class="red">DAY
                </h1>
            </div>
                                    
            <div class="food">
                <b-container fluid class="p-0">
                    <b-row>
                        <b-col sm="12" md="7" class="food_preview"><b-img :src="imageSelected" /></b-col>
                        <b-col sm="12" md="5" class="description">
                            <label>MENU</label>
                            <p>Skills and refinement are shown in every dish to the desserts curated by own pastry chef who has great passion in baking.  Incorporating seasonal and non-traditional ingredients to create modern and borderless imaginings of the western cuisine, the passionate culinary team is credited for being outstanding and versatile with creative menus changed periodically. You are bound to have an amazing discovery and surprise every time on your visit.</p>
                            <div class="list">
                                <div class="item" v-for="(item, index) in images" :key="index">
                                    <b-img :src="item" @click="() => onClick(index)" />
                                </div>
                            </div>
                        </b-col>
                    </b-row>
                </b-container>
            </div>
        </div>
    </section>
</template>
<script>

import food1 from '@/assets/day/1.jpg'
import food2 from '@/assets/day/2.jpg'
import food3 from '@/assets/day/3.jpg'
import food6 from '@/assets/day/6.jpg'
import food7 from '@/assets/day/7.jpg'
import food9 from '@/assets/day/9.jpg'
import food10 from '@/assets/day/10.jpg'
import food11 from '@/assets/day/11.jpg'
import food12 from '@/assets/day/12.jpg'
import food13 from '@/assets/day/13.jpg'
import food14 from '@/assets/day/14.png'
import food15 from '@/assets/day/15.jpg'

export default {
    components: {
    },
    data(){
        return {
            imageSelected: food1,
            images: [
                food1,
                food2,
                food14,
                food15,
                food12,
                food11,
                food3,
                food6,
                food7,
                food9,
                food10,
                food13
            ],
        }
    },
    mounted() {
    },
    methods: {
        onClick(index) {
            this.imageSelected = this.images[index];
        }
    }
}
</script>
<style lang="scss" scoped>
section {
    padding-top: 30px;
    padding-bottom: 30px;
    position: relative;
    background-color: rgba(255,182,0, 0.2);
}
.yellow-block {

    background-color: rgba(255,182,0, 0.2);
    position: absolute;
    top:0px;
    z-index: 1;
    height: 80%;
    width: 100%;
    @media screen and (max-width: 767px) {
        height: 100%;
    }
}
.menu {
    position: relative;
    z-index: 2;
}
.title {
    text-align: right;
    display: flex;
    align-items: center;
    margin-bottom: 0px;
    width: 100%;
    @media screen and (max-width: 767px) {
        border-top: 1px solid #cf3b01;
    }
}
.title h1{
  display: inline-block;
  margin-left: 10px;
  padding: 0px;
  @media screen and (max-width: 767px) {
    margin-left: auto;
  }
}
.title::before{
  content:'';
  width: 100%;
  height: 2px;
  display: inline-block;
  background-color: #cf3b01;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
.description {
    text-align: right;
    font-size: 16px;
    color:#605757;
    padding: 0px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        text-align: left;
    }
}
label {
    font-size: 14px;
    font-weight: bolder;
    padding: 2px 5px;
    color:#605757;
    border: 1px solid #605757;
    margin-bottom: 20px;
}

.food {
    // background-image: url("~@/assets/bg_food.svg");
    background-position: left bottom;
    background-repeat: no-repeat;
    background-size: 70%;
    padding-bottom: 8vw;
}
.food_preview {
    @media screen and (max-width: 767px) {
        display: none;
    }
    img {
        width: 100%;
        height: auto;
        margin-bottom: 40px;
    }
}
.list {
    width: 100%;
    overflow: hidden;
    @media screen and (max-width: 767px) {
        text-align: center;
        width: 150%;
        margin: 0 -25%;
    }
    @media screen and (max-width: 414px) {
        // width: 100%;
    }

    .item {
        height: 100px;
        width: auto;
        display: inline-block;
        margin: 10px;
        @media screen and (max-width: 767px) {
            width: 30%;
            max-width: 150px;
            height: auto;
        }

        img {
            height: 100%;
            width: auto;
            cursor: pointer;
            @media screen and (max-width: 767px) {
                height: auto;
                width: 100%;
            }
        }
    }
}

@media screen and (max-width: 767px) {
}
</style>
