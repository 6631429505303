<template>
    <section id="speak">
        <div class="content">
            <h1>FOOD SPEAKS FOR ITSELF</h1>
        </div>
    </section>
</template>
<script>
export default {
    name: 'Speak'
}
</script>
<style lang="scss" scoped>
$red:rgb(201, 59, 0);

.content {
    font-size: 30px;
    color: $red;
    text-align: center;
    padding: 100px 0px;
    h1 {
        border-bottom: 2px solid $red;
        line-height: 1.4em;
        width: fit-content;
        margin: 0 auto;
    }
}
</style>