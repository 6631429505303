<template>
    <section id="address">
        <div class="content" style="text-align:left">
            <div class="title"><h1 class="white">CONTACT US</h1></div>
            <b-row>
                <b-col sm="12" md="6">
                    <GmapMap
                        ref="mapRef"
                        :center='center'
                        :zoom='17'
                        style='width:100%;  height: 250px;'
                    >
                    <GmapMarker
                        :position="center"
                    />
                    </GmapMap><br/>
                    
                </b-col>
                <b-col sm="12" md="6">
                    <div class="address">
                        <b>ADDRESS:</b>
                        <p>8/f, sl ginza, 68 electric road, tin hau</p>
                    </div>
                    <div class="hour">
                        <b>OPENING HOURS:</b>
                        <p>MON - SUN: 12:00 - 23:00</p>
                    </div>
                    <div class="tel">
                        <b>TELEPHONE:</b>
                        <p>+852 3583 3188</p>
                    </div>
                </b-col>
            </b-row>
        </div>
    </section>
</template>
<script>
import store from '@/store'

export default {
    data(){
        return{
            booking_url:store.url.reserve,
            phone:store.phone,
            fb_url:store.url.fb,
            ig_url:store.url.ig,
            wts_url:store.url.wts,
            center: { lat: 22.2847859, lng: 114.1917773 },
        }
    },
    mounted() {
        // this.geolocate();
        this.$refs.mapRef.$mapPromise.then((map) => {
            map.panTo({lat: this.center.lat, lng: this.center.lng})
            })
    },
    methods: {
        geolocate: function() {
            navigator.geolocation.getCurrentPosition(position => {
                this.center = {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
                };
            });
        },
    }
}
</script>
<style lang="scss" scoped>
section {
    background-color:#681100;

    p,
    b {
        color: rgba(255,182,0);
        margin-bottom: 0px;
    }
}
.booking {
    border: 2px solid #8E6919;
    padding: 30px;
    text-align: center;
    width: 100%;
    max-width: 450px;
    margin-bottom: 20px;
}
.address,
.hour,
.tel{
    font-size: 16px;
    font-weight: bolder;
    text-transform: uppercase;

    b {
        font-size:12px;
        font-weight: lighter;
    }
}
.btn-booking {
    padding: 20px;
    color: #FFFFFF;
    background-color: #8E6919;
    width: 100%;
}
a{
    display: block;
    margin-bottom:10px;
    color: #312a29;
}
</style>
