<template>
    <section id="sustainability">
        <div class="content" style="text-align:left;">
            <b-row>
                <b-col xs="12" lg="12">
                    <div class="description"><p>Aside from a tantalizing and tasting menu, On the Spot offers all day bar service. </p>
                    <p>A bar allows diners a full preview of the drama in making all kinds of beverages. It offers you to have glasses of wine to sip at any time from noon till night. </p>
                    </div>
                </b-col>
                <b-col md="12" lg="12">
                    <div class="food">
                        <div fluid class="p-0" style="position: relative">
                            <div ref="swiper" class="swiper">
                                <div class="swiper-wrapper">
                                <!-- Slides -->
                                <div class="swiper-slide" v-for="(img,index) in images" :key="index"> <b-img thumbnail fluid :src="img" :alt="`image ${index}`"></b-img></div>
                                </div>

                                <!-- Navigation arrows -->
                                <div class="swiper-button-prev"></div>
                                <div class="swiper-button-next"></div>

                                <div class="swiper-pagination"></div>
                            </div>
                        </div>
                </div>
                </b-col>
            </b-row>
            <div class="title"><h1 class="yellow">NIGHT</h1></div>
        </div>
    </section>
</template>
<script>
import Swiper, { EffectCoverflow, Pagination, Navigation } from 'swiper'

// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/modules/effect-coverflow/effect-coverflow.min.css'

import food1 from '@/assets/night/1.jpg'
import food2 from '@/assets/night/2.jpg'
import food3 from '@/assets/night/3.jpg'
import food4 from '@/assets/night/4.jpg'
import food5 from '@/assets/night/5.jpg'
import food6 from '@/assets/night/6.jpg'
import food7 from '@/assets/night/7.jpg'
import food8 from '@/assets/night/8.jpg'
import food9 from '@/assets/night/9.jpg'
import food10 from '@/assets/night/10.jpg'
import food11 from '@/assets/night/11.jpg'
import food12 from '@/assets/night/12.png'
import food13 from '@/assets/night/13.jpg'
import food14 from '@/assets/night/14.jpg'
import food15 from '@/assets/night/15.jpg'
import food16 from '@/assets/night/16.jpg'

export default {
    data(){
        return {
            images:[
                food1,
                food2,
                food3,
                food4,
                food5,
                food6,
                food7,
                food8,
                food9,
                food10,
                food11,
                food12,
                food13,
                food14,
                food15,
                food16
            ],
            activeIndex: 0
        }
    },
    setup() {
        return {
        modules: [EffectCoverflow, Pagination],
        };
    },
    mounted() {
        this.initSwiper();
    },
    methods: {
        slidePre() {
            this.$refs.slider.$emit('slidePre')
        },
        slideNext() {
            this.$refs.slider.$emit('slideNext')

        },
        initSwiper () {
            Swiper.use([Navigation]);
            new Swiper(this.$refs.swiper, {
                // configure Swiper to use modules
                modules: [EffectCoverflow, Pagination],
                effect:'coverflow',
                slidesPerView: 1,
                loop: true,
                centeredSlides: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                    hideOnClick: true
                },
                coverflowEffect:{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    // scale: 1.5,
                    slideShadows: true,
                },
                breakpoints: {
                    577: {
                        slidesPerView: 3
                    }
                },
                // Events
                on: {
                    slideChange: (swiper) => {
                        this.activeIndex = swiper.realIndex
                    },
                }
            })
        }
    },
}
</script>
<style lang="scss" scoped>
section {
    background-color: #0e0a21;
}
.title {
    display: flex;
    align-items: center;
    @media screen and (max-width: 767px) {
        border-bottom:1px solid #ffbb02;
        margin: 0 20px;
    }
}
.title h1{
  display: inline-block;
  margin-right: 10px;
  padding: 0px;
}
.title::after{
  content:'';
  width: 100%;
  height: 2px;
  display: inline-block;
  background-color: #ffbb02;
  @media screen and (max-width: 767px) {
    display: none;
  }
}
h1 {
    text-transform: uppercase;
    color: #413838;
}
p{
  color: #fff;
}
.content {
    background-image: url("~@/assets/bg_food.svg");
    background-position: right top;
    background-size: 70%;
    background-repeat: no-repeat;
}
.description {
    padding-top: 30px;
    p {
        font-size: 23px;
    }
}
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.swiper-wrapper {
    align-items: baseline;
}
.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100%;
}
</style>
<style>
.swiper-pagination-bullet {
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 0px;
}
.swiper-pagination-bullet-active {
    background-color: #fff
}
.swiper-button-next,
.swiper-button-prev {
    top:auto;
    bottom: 10px;
    height: 24px;
    cursor: pointer;
    z-index: 999;  
}
.swiper-button-next::after,
.swiper-button-prev::after {
    color:#fff;
    font-size: 12px;
}
.swiper-button-next {
    left: 70%;
    right: auto;
}
.swiper-button-prev {
    left: auto;
    right: 70%;
}
@media only screen and (max-width: 568px)  {
    .swiper-button-next {
        left: 90%;
    }
    .swiper-button-prev {
        right: 90%;
    }
}

.img-thumbnail {
    padding: 0px;
    border: none;
    border-radius: 0;
}
</style>