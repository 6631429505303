<template>
<div>
  <div class="banner">
    <div class="video">
        <video id="bgVideo" autoplay muted playsinline loop preload="true" style="object-fit: cover;">
            <source :src="bg_video" type="video/mp4">
        </video>
    </div>
    <div class="mass">
      <img :src="images[0]" class="arrow" />
    </div>
      <h1>Contemporary<br/>
        Western Cuisine<br/>
        With <b>Creative Flavors</b></h1>
    </div>
</div>
</template>
<script>
import store from '@/store'
import video from '@/assets/videos/OTS_Final_clean_ver.mp4'
import arrow from '@/assets/arrow.svg'


export default {
    name:'banner',
    data() {
      return {
        loading: false,
        bg_video:video,
        phone:store.phone,
        fb_url:store.url.fb,
        ig_url:store.url.ig,
        wts_url:store.url.wts,
        images:[arrow],
      }
    },
    mounted() {
        document.getElementById('bgVideo').play();
    },
    methods: {
        toSection:function(ele){
            const iTop  = document.querySelector(ele).offsetTop - 50;

            try {
                window.scrollTo({
                top: iTop,
                left: 0,
                behavior: 'smooth'
                });
            } catch(e) { console.log(e) }
        },
    }
}
</script>
<style lang="scss" scoped>
.banner{
  position: relative;
  display:flex;
  height: 100vh;
  overflow: hidden;

  @media screen and (max-width: 767px) {
    height: 560px;
  }

  .video,
  .mass {
    position: absolute;
    width: 100%;
    height: 100%;
    text-align: center;
  }
  .mass {
    background: linear-gradient(to right, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,0.6) 100%);
    
    img {
      width: 15px;
      height: auto;
      position: absolute;
      bottom: 5px;
    }
  }
  .video {
    overflow: hidden;
    width: 100vw;

    video {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }
    background-color: #000;
    // background-image: url('~@/assets/banner.jpeg');
    // background-size: cover;
    // background-position: center top;
  }

  h1 {
    padding: 0px 30px;
    font-size: 68px;
    font-weight: lighter;
    align-self: center;
    position: absolute;
    color:#ffffff;
    text-align: left;
    // text-shadow: 5px 5px rgba( 255,255,255,0.2);
    width: 100vw;

    @media screen and (max-width: 767px) {
        font-size: 50px;
    }

    b {
      font-size: 1.4em;
    }
  }
}

</style>
