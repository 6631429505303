<template>
    <section id="story">
        <div class="content">
        <b-row>
            <b-col sm="12" md="6" class="p-0 left">
                <div class="block n1"></div>
                <div class="block n2"></div>
                <div class="block n3"></div>
            </b-col>
            <b-col sm="12" md="6" class="p-0 right">
                <div class="block n4"></div>
                <div class="block n5"></div>
                <div class="block n6">STORY</div>
                <div class="block n7"></div>
                <div class="block n8"></div>
                <div class="description">
                    <p>Joey strives to build up his unique style of western cuisine restaurant where East meets West and develop a household name in Hong Kong where not only his loved ones but any one in the city of Asia can enjoy Western cuisines at its finest.</p>
                    <p>With the most important qualities of any master chef; Passion and Stamina, Chef Joey and his energetic and dynamic culinary team are driven and dedicated in creating a versatile spot that can be well suited for comfortable neighbourhood friendly hang outs, catching up with long missed friends over coffee, or for a more refined night out with a special date.</p>
                </div>
                <div class="block n9"></div>
            </b-col>
        </b-row>
    </div>
    </section>
</template>
<script>

export default {
    data(){
        return {
        }
    }
}
</script>
<style lang="scss" scoped>
$margin:10px;

section {
    transition: all;
}
h1 {
    padding: 0px !important;
}
.left {
    width: 50%;
    @media only screen and (max-width: 768px)  {
        display: none;
    }
}
.right {
    width: 50%;
    @media only screen and (max-width: 768px)  {
        width: 100%;
    }

}
.description {
    color: #555555;
    padding-left: $margin;
    width: 42vw;

    p {
        line-height: 2em;
        margin-bottom: 0px;
    }

    @media only screen and (max-width: 768px)  {
        width: 100%;
    }
}

.block {
    display: inline-flex !important;
    background-color: #ffbb02;
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    margin: $margin;
    vertical-align: bottom;
    overflow: hidden;
}

.n1 {
    // max-width: calc(100% - $margin);
    width: clamp(340px, 42vw, 676px);
    height: clamp(100px, 46.5vw, 800px);
    img { width: 100% }
    margin-left: 0px;
    margin-right: 0px;
    background-image: url('~@/assets/story/7.jpg');
    background-position: center bottom;

}
.n2 {
    width: clamp(130px, 20.5vw, 330px);
    height: clamp(100px, 30vw, 500px);
    margin-left: 0px;
    background-image: url('~@/assets/story/1.jpg');
}
.n3 {
    width: clamp(130px, 20.2vw, 330px);
    height: clamp(100px, 30vw, 500px);
    margin-right: 0px;
    background-image: url('~@/assets/story/3.jpg');
}

.n4 {
    width: clamp(70px, 9vw, 300px);
    height: clamp(120px, 14vw, 250px);
    background-color: #cf3b01;

    @media only screen and (max-width: 768px)  {
        width: calc(25% - 2*$margin);
    }
}

.n5 {
    width: clamp(70px, 9vw, 300px);
    height: clamp(120px, 14vw, 250px);
    background-image: url('~@/assets/story/4.jpg');

    @media only screen and (max-width: 768px)  {
        width: calc(25% - 2*$margin);
    }
}

.n6 {
    width: fit-content;
    height: fit-content;
    background: transparent;
    font-weight: lighter;
    font-size: clamp(60px, 8vw, 130px);
    line-height: 1em;
    color: #cf3b01;
    @media only screen and (max-width: 768px)  {
        font-size: 18vw;
    }
}

.n7 {
    width: clamp(70px, 20.6vw, 350px);
    height: clamp(120px, 13vw, 200px);
    background-image: url('~@/assets/story/5.jpg');

    @media screen and (max-width: 767px) {
        width: calc(50% - 2*$margin);
    }
}
.n8 {
    width: clamp(70px, 19vw, 350px);
    height: clamp(120px, 13vw, 200px);
    background-image: url('~@/assets/story/6.jpg');

    @media screen and (max-width: 767px) {
        width: calc(50% - 2*$margin);
    }
}

.n9 {
    width: 42vw;
    height: 100px;
    @media screen and (max-width: 767px) {
        display: none !important;
    }
}
</style>